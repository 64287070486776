import { useTranslation } from 'next-i18next'
import type { FC } from 'react'
import { memo } from 'react'

import { selectIsOpenLayovers } from '@redux/features/recommendedRoutes/recommendedRoutes.selectors'
import { setIsOpenLayovers } from '@redux/features/recommendedRoutes/recommendedRoutes.slice'
import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

import FlagIcon from '@base/FlagIcon'
import Title from '@typography/Title'

import { ContentWrapper, Label, LayoverContainer, Wrapper } from './styles'

type Props = {
  isMainPage: boolean
  layoverValue: SearchStationsOptionType
}

const StationLayover: FC<Props> = ({ isMainPage, layoverValue }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isOpenPopup = useAppSelector(selectIsOpenLayovers)

  const onClick = () => {
    dispatch(setIsOpenLayovers(!isOpenPopup))
  }

  const content = (
    <>
      <Label>{t(isMainPage ? 'recommendedRoutes.layoverIn' : 'recommendedRoutes.layover')}</Label>
      <Title level={6}>{layoverValue.label}</Title>
    </>
  )

  return (
    <LayoverContainer>
      <Wrapper $isMainPage={isMainPage} onClick={onClick}>
        {isMainPage ? content : <ContentWrapper>{content}</ContentWrapper>}
        {layoverValue.country_code && <FlagIcon code={layoverValue.country_code} width="24px" />}
      </Wrapper>
    </LayoverContainer>
  )
}

export default memo(StationLayover)
