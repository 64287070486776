import styled from 'styled-components'

import { searchFormSizes } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'
import UsersAltIcon from '@iconscout/unicons/svg/line/users-alt.svg'
import { ellipsisText } from '@styles/typography/textStyles'
import { title6Styles } from '@styles/typography/title'
import { CaptionStyled } from '@typography/Caption/styles'
import { StyledText } from '@typography/Text'

export const PassengersLabelWrapper = styled.button<{ $compactView: boolean }>`
  height: ${searchFormSizes.fieldHeightDesktop}px;
  display: grid;
  row-gap: 4px;
  align-content: center;
  justify-items: self-start;
  grid-column-gap: ${p => (p.$compactView ? 12 : 8)}px;
  grid-template-columns: ${p => (p.$compactView ? '' : '32px auto')} 1fr;
  grid-template-rows: ${p => (p.$compactView ? '18px' : '')} 24px;
  grid-template-areas: ${p => (p.$compactView ? "'caption' 'passengers-info'" : "'icon caption passengers-info'")};
  border: none;
  background: none;

  ${CaptionStyled}, ${StyledText} {
    grid-area: caption;
    color: ${p => (p.$compactView ? p.theme.colors.fontSecondaryForm : p.theme.colors.fontContrast)};
  }

  ${p => p.theme.mediaQueries.mobile} {
    height: ${searchFormSizes.fieldHeightMobile}px;
  }
`

export const UsersIcon = styled(UsersAltIcon)`
  grid-area: icon;
  fill: ${p => p.theme.colors.icon};
  margin-inline-end: 8px;
`

export const PassengersInfo = styled.span`
  grid-area: passengers-info;
  ${title6Styles};
  color: ${p => p.theme.colors.fontSecondaryForm};
  ${ellipsisText};
  width: 100%;
`

export const Children = styled.span`
  margin-inline-start: 12px;
`

export const ChildrenAges = styled.span`
  color: ${p => p.theme.colors.fontSecondaryForm};
`
