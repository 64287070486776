import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import FlagIcon from '@base/FlagIcon'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import getAppConfig from '@services/config/config'
import { ellipsisText } from '@styles/typography/textStyles'
import { mediaQueries } from '@themes/mediaQueries'
import Title, { Title4, Title5 } from '@typography/Title'

const { publicRuntimeConfig } = getAppConfig()

type Props = {
  country_code?: string
  flagFirst?: boolean
  label: string
}

const StationOption: FC<Props> = ({ country_code, flagFirst, label }) => {
  const isMobileTablet = useMediaQuery(mediaQueries.mobileTablet)

  return (
    <OptionWrapper>
      <Title level={isMobileTablet ? 5 : 4} style={{ order: flagFirst ? 1 : 0 }}>
        {label}
      </Title>
      {country_code && (
        <FlagIcon code={country_code} flagDomain={publicRuntimeConfig?.additionalWidgetDomain} width="24px" />
      )}
    </OptionWrapper>
  )
}

export const OptionWrapper = styled.div`
  display: flex;
  gap: 14px;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  max-width: 100%;

  ${Title4}, ${Title5} {
    flex: 1;
    ${ellipsisText};
  }
`

export default memo(StationOption)
