import type { FC, HTMLAttributes } from 'react'
import { memo } from 'react'
import { createPortal } from 'react-dom'
import styled, { keyframes } from 'styled-components'

const ShadowMask: FC<HTMLAttributes<HTMLDivElement> | undefined> = props => {
  return createPortal(<ShadowMaskStyled {...props} />, document.body)
}

const shadowMaskAppear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
`

const ShadowMaskStyled = styled.div`
  z-index: 1;
  position: fixed;
  inset: 0;
  background: ${p => p.theme.colors.black};
  opacity: 0.75;
  animation: ${shadowMaskAppear} ${p => p.theme.transitions.default};
`

export default memo(ShadowMask)
