import { useTranslation } from 'next-i18next'
import type { FC } from 'react'
import styled from 'styled-components'

import AngleLeftIcon from '@iconscout/unicons/svg/line/angle-left.svg'
import { title6Styles } from '@styles/typography/title'
import Title from '@typography/Title'

type Props = {
  onClose: () => void
}

const Header: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Button onClick={onClose} />
      <Title level={5}>{t('searchForm.selectPassengers.label')}</Title>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  display: inline-flex;
  justify-content: center;
  position: relative;

  ${p => p.theme.mediaQueries.mobile} {
    margin-top: 16px;
    h5 {
      ${title6Styles};
    }
  }
`

const Button = styled(AngleLeftIcon)`
  display: none;

  ${p => p.theme.mediaQueries.mobile} {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    fill: ${p => p.theme.colors.fontSecondary};
  }
`

export default Header
